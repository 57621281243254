<template>
  <roles
      :repository="repository"
      label="roles"
      permission-label="Role"
  />
</template>

<script>
import Roles from '@/components/roles/manage-roles.vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    Roles,
  },
  data() {
    return {
      repository: RepositoryFactory.create('roleRepo'),
    }
  },
}
</script>
