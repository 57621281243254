<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              cols="12"
              md="6"
          >
            <label> {{ $i18n.t('Show') }}</label>
            <v-select
                v-model="perPage"
                :clearable="false"
                :options="perPageOptions"
                class="per-page-selector d-inline-block mx-50"
            />
            <label> {{ $i18n.t('entries') }}</label>
          </b-col>
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  :to=" { name:label+'-add' } "
                  variant="primary"
              >
                <span class="text-nowrap">{{ $i18n.t('roles.' + label + '.add') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <loading :show="is_loading">
        <b-table
            :fields="fields"
            :items="items"
            :empty-text="$i18n.t('there_are_no_data')"
            hover
            responsive="sm"
            show-empty
        >
          <!-- Column: Actions -->
          <template
              v-if="isCanDo('update'+permissionLabel) || isCanDo('delete'+permissionLabel)"
              #cell(actions)="data"
          >
            <b-dropdown
                no-caret
                variant="link"
            >
              <template #button-content>
                <feather-icon
                    class="align-middle text-body"
                    icon="MoreVerticalIcon"
                    size="16"
                />
              </template>
              <b-dropdown-item
                  v-if="isCanDo('update'+permissionLabel)"
                  :to="{ name: label+'-edit', params: { id: data.item.id } }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $i18n.t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                  v-if="isCanDo('delete'+permissionLabel)"
                  @click="confirmDelete(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $i18n.t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </loading>

      <div class="mx-2 mb-2">
        <b-row>
          <!-- Pagination -->
          <b-col
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
              cols="12"
              sm="12"
          >

            <b-pagination
                v-model="page"
                :per-page="perPage"
                :total-rows="totalPages"
                class="mb-0 mt-1 mt-sm-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

export default {
  props: ['label', 'permissionLabel', 'repository'],
  data() {
    return {
      totalPages: 1,
      page: 1,
      perPage: 10,
      perPageOptions: [10, 25, 50, 100],
      items: [],
      is_loading: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: this.$i18n.t('Name'),
          sortable: true
        },
        {
          key: 'actions',
          label: this.$i18n.t('Actions'),
          sortable: false,
        }
      ]
    }
  },
  watch: {
    perPage() {
      this.getData()
    },
    page() {
      this.getData()
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.items = []
      this.is_loading = true
      const params = {
        page: this.page,
        perPage: this.perPage,
      }
      this.repository.getAll(params).then(res => {
        const response = res.data
        response.data.forEach((item) => {
          const data = {}
          data.id = item.id
          data.name = item.name
          this.items.push(data)
        });
        this.totalPages = response.meta.total
        this.is_loading = false
      })
    },
    refetchData() {
      this.getData()
    },
    confirmDelete(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.is_loading = true
          this.repository.delete(item.id).then(response => {
            this.refetchData()
            this.$swal({
              icon: 'success',
              title: `${this.$i18n.t('Deleted')}!`,
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          this.is_loading = false
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
